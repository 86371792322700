 $(document).foundation();


 /**
  * OC close it on click
  */
 jQuery('.oc .menu-item a').click(function() {
     jQuery('.js-off-canvas-exit').click();
 })

 /**
  * Smooth scroll script
  */
 jQuery(function() {
     jQuery('.menu-item a').click(function() {
         if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
             var target = $(this.hash);
             target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
             if (target.length) {
                 $('html, body').stop(true, false).animate({
                     scrollTop: target.offset().top - 50
                 }, 1000);
                 return false;
             }
         }
     });
 });

 jQuery('.menu-item-kontakt a').click(function() {
     jQuery('.toggle-form').click();
 });

 // On scroll give fixed classr 
 jQuery(window).scroll(function() {
     if (jQuery(this).scrollTop() >= 100) {
         jQuery('body').addClass('fixed');
     } else {
         jQuery('body').removeClass('fixed');
     }
 });

 // Global trigger on page ready
 jQuery(document).ready(pageLoaded);

 function pageLoaded() {
     jQuery("body").addClass("loaded");

     // Initialize fancybox
     jQuery("a.fancybox").fancybox({
         helpers: {
             overlay: {
                 locked: false
             }
         },
         'transitionIn': 'elastic',
         'transitionOut': 'elastic',
         'speedIn': 200,
         'speedOut': 200,
         'overlayShow': true,
         'padding': 0,

     });
     jQuery("#toggleGalleries").click(function() {
         jQuery(this).toggleClass("expanded");
         jQuery(".hidden-row").slideToggle();
     });

     jQuery(".toggle-form").click(function() {
         jQuery(".contact-form.hidden").slideDown();
     });

     jQuery(window).scroll();

     console.log("-> pageLoaded() ->");
     onLoad();

     console.log("<- pageLoaded() <-");
     return true;
 }